export const MAIN_CONFIG = {
  app_name: 'Hélios',
  access_perm: 'helios::ACCESS',
  layers: {
    patrimoine_solaire_propriete: {
      view_slug: 'geo',
      props: {
        type: 'fill',
        paint: {
          'fill-color': '#d75b5b',
        },
      },
    },
    patrimoine_solaire_propriete10: {
      view_slug: 'geo',
      props: {
        type: 'fill',
        paint: {
          'fill-color': '#d75b5b',
        },
      },
    },
    patrimoine_solaire_propriete15: {
      view_slug: 'geo',
      props: {
        type: 'fill',
        paint: {
          'fill-color': '#d75b5b',
        },
      },
    },
    patrimoine_solaire_propriete20: {
      view_slug: 'geo',
      props: {
        type: 'fill',
        paint: {
          'fill-color': '#d75b5b',
        },
      },
    },
    patrimoine_solaire_propriete25: {
      view_slug: 'geo',
      props: {
        type: 'fill',
        paint: {
          'fill-color': '#d75b5b',
        },
      },
    },
    patrimoine_solaire_voie: {
      view_slug: 'geo',
      props: {
        type: 'line',
        paint: {
          'line-color': '#222222',
          'line-width': 1,
        },
      },
    },
    patrimoine_solaire_quai: {
      view_slug: 'geo',
      props: {
        type: 'line',
        paint: {
          'line-color': '#AAAAAA',
          'line-width': 2,
        },
      },
    },
    patrimoine_solaire_bati: {
      view_slug: 'geo',
      props: {
        type: 'fill',
        paint: {
          'fill-color': '#646464',
        },
      },
    },
  },
}

export default MAIN_CONFIG
